import { render, staticRenderFns } from "./NewsFormEdit.vue?vue&type=template&id=53fae064&scoped=true&"
import script from "./NewsFormEdit.vue?vue&type=script&lang=js&"
export * from "./NewsFormEdit.vue?vue&type=script&lang=js&"
import style0 from "./NewsFormEdit.vue?vue&type=style&index=0&id=53fae064&prod&scoped=true&lang=css&"
import style1 from "./NewsFormEdit.vue?vue&type=style&index=1&id=53fae064&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fae064",
  null
  
)

export default component.exports